import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function PopupForm(props) {
  // eslint-disable-next-line react/prop-types
  const { openPopup, setOpenPopup } = props;

  const handleClickOpen = () => {
    setOpenPopup(true);
  };

  const handleClose = () => {
    setOpenPopup(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Open form dialog
      </Button>
      <Dialog open={openPopup} onClose={handleClose}>
        <DialogTitle>Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates occasionally.
          </DialogContentText>
          {/* <TextField margin="dense" id="name" label="Email Address" type="email" fullWidth variant="standard" /> */}
          <div>
            <TextField autoFocus fullWidth type="email" margin="dense" label="Email address" />
            <TextField autoFocus fullWidth type="email" margin="dense" label="Email address" />
          </div>
          <TextField autoFocus fullWidth type="email" margin="dense" label="Email address" />
          <TextField autoFocus fullWidth type="email" margin="dense" label="Email address" />
          <TextField autoFocus fullWidth type="email" margin="dense" label="Email address" />
          <TextField autoFocus fullWidth type="email" margin="dense" label="Email address" />
          <TextField autoFocus fullWidth type="email" margin="dense" label="Email address" />
          <TextField autoFocus fullWidth type="email" margin="dense" label="Email address" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Subscribe</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
