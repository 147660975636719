import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { CSVLink } from 'react-csv';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';

// eslint-disable-next-line import/named

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import PopupForm from '../components/PopupForm';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';

// mock
// import USERLIST from '../_mock/user';
import useData from '../_mock/data';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'fatherName', label: 'Father Name', alignRight: false },
  { id: 'class', label: 'Class', alignRight: false },
  { id: 'medium', label: 'Medium', alignRight: false },
  { id: 'feesStatus', label: 'Fees Status', alignRight: false },
  { id: 'group', label: 'Group', alignRight: false },
  { id: '' },
];

const headers = [
  { label: 'Name', key: 'name' },
  { label: 'Father', key: 'father' },
  { label: 'Mother', key: 'mother' },
  { label: 'Dob', key: 'dob' },
  { label: 'ChildId', key: 'childId' },
  { label: 'Class', key: 'class' },
  { label: 'State', key: 'state' },
  { label: 'Mobile Number', key: 'mobileNumber' },
  { label: 'District', key: 'district' },
  { label: 'Parent Mobile Number', key: 'parentMobileNumber' },
  { label: 'Town', key: 'town' },
  { label: 'Medium', key: 'medium' },
  { label: 'Nomination Number', key: 'nominationNumber' },
  { label: 'Post Office', key: 'postOffice' },
  { label: 'Father Annual Income', key: 'fatherAnnualIncome' },
  { label: 'Admission Type', key: 'admissionType' },
  { label: 'Permanent Address', key: 'permanentAddress' },
  { label: 'Pin Code', key: 'pinCode' },
  { label: 'Group', key: 'group' },
  { label: 'Language One', key: 'languageOne' },
  { label: 'Language Two', key: 'languageTwo' },
  { label: 'Subject One', key: 'diversifiedSubjectOne' },
  { label: 'Subject Two', key: 'diversifiedSubjectTwo' },
  { label: 'SubjectThree', key: 'diversifiedSubjectThree' },
  { label: 'Additional Subject', key: 'additionalSubject' },
  { label: 'Category', key: 'category' },
  { label: 'Referal', key: 'referal' },
  { label: 'Adhar Card', key: 'adharCardNumber' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  const [openPopup, setOpenPopup] = useState(false);
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [editUser, setEditUser] = useState({});

  const { data } = useData();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const csvReports = {
    filename: 'student.csv',
    headers,
    data,
  };

  console.log(data);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const editUserData = (row) => {
    setEditUser(row);
    console.log('editUserData', row);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const filteredUsers = applySortFilter(data, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <>
      <Page title="User">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              User
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Button
                variant="contained"
                component={RouterLink}
                sx={{ mx: 2 }}
                to="#"
                startIcon={<Iconify icon="fluent:print-24-filled" />}
              >
                Print
              </Button>
              <CSVLink {...csvReports}>Download Excel</CSVLink>
              <Button variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="charm:download" />}>
                Download
              </Button>
            </Stack>
          </Stack>

          <Card>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={data.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { id, name, group, medium, father, fees } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, name)} />
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={name} src="#" />
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{father}</TableCell>
                          <TableCell align="left">{row.class}</TableCell>
                          <TableCell align="left">{medium}</TableCell>
                          <TableCell align="left">
                            <Label variant="ghost" color={fees === false ? 'error' : 'success'}>
                              {sentenceCase(fees === false ? 'Dues' : 'No Dues')}
                            </Label>
                          </TableCell>
                          <TableCell align="left">{group}</TableCell>
                          <TableCell align="right">
                            <UserMoreMenu setOpenPopup={setOpenPopup} editUserData={editUserData} row={row} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>
      <PopupForm openPopup={openPopup} setOpenPopup={setOpenPopup} />
    </>
  );
}
