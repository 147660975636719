import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const secondaryAppConfig = {
  apiKey: 'AIzaSyDe4ZNI_Sq1Gq9yytIQfEhXh_aphcZot9M',
  authDomain: 'patel-school-karera.firebaseapp.com',
  databaseURL: 'https://patel-school-karera-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'patel-school-karera',
  storageBucket: 'patel-school-karera.appspot.com',
  messagingSenderId: '1084716353680',
  appId: '1:1084716353680:web:2a4f9685f005bf56903015',
  measurementId: 'G-HMWQXBK4DE',
};

const firebaseConfig = {
  apiKey: 'AIzaSyBGuxpwmFfsVXCMoVNiauGrHb_F9ySD4mc',
  authDomain: 'dashboard-patel-school.firebaseapp.com',
  projectId: 'dashboard-patel-school',
  storageBucket: 'dashboard-patel-school.appspot.com',
  messagingSenderId: '1497226535',
  appId: '1:1497226535:web:78bbe8961ac7d666721d02',
  measurementId: 'G-GX52ZT0SDF',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
const secondaryApp = initializeApp(secondaryAppConfig, 'secondary');
// const appSecond = initializeApp(secondFirebaseConfig);
// export const db = getFirestore(app);
export const db = getFirestore(secondaryApp);
